import React, {useEffect, useRef, useState} from 'react';
import {Box, LinearProgress, Paper,} from '@material-ui/core';
import {FileCopy, PlayArrow, Stop, SwapHoriz,} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import moment from "moment";

// function CircularProgressWithLabel(props) {
//   return (
//     <Box position="relative" display="inline-flex">
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         top={0}
//         left={0}
//         bottom={0}
//         right={0}
//         position="absolute"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//       >
//         {`${Math.round(props.value,)}%`}
//       </Box>
//     </Box>
//   );
// }

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box width="100%" mr={1} alignItems="center" justifyContent="center">
        <LinearProgress variant="determinate" style={{marginBottom:0}} {...props} />
      </Box>
      <Box minWidth={35}>
        {props.value}{props.variant === "determinate" ? "%" : ""}
      </Box>
    </Box>
  );
}

export default function ChannelList(props) {
  const intl = useIntl();
  const ref = useRef({});
  const [ids, setIds] = useState([]);

  const renderConnectorTitle = (row, type) => {
    if (row[type+'_connector']) {
      return row[type+'_connector'].title;
    }
    else {
      return 'OpenDIMS';
    }
  }

  const columns = [
    {title: intl.formatMessage({id: 'channels.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'channels.list.column.source', defaultMessage: 'Source'}),
      field: 'source_connector.title',
      type: 'string',
      filtering: false,
      render: (row) => renderConnectorTitle(row, 'source')
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.destination', defaultMessage: 'Destination'}),
      field: 'destination_connector.title',
      type: 'string',
      filtering: false,
      render: (row) => renderConnectorTitle(row, 'destination')
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.processed_at', defaultMessage: 'Last processed at'}),
      field: 'last_job_started_at',
      render: row => row.last_job_started_at ? moment(row.last_job_started_at).format(intl.formatMessage({id:"common.datetime.format"})) : ''
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.job_progress', defaultMessage: 'Job Progress'}),
      field: 'progress_total',
      render: row => row.job_id !== null ? <LinearProgressWithLabel value={row.progress_total ? Math.round(row.progress / row.progress_total * 100) : row.progress} variant={row.progress_total ? "determinate" : "indeterminate"}/> : ''
    }
  ];

  const startJob = (id) => {
    setIds([...ids, id])
    client.mutate('{channelUpdate(id:' + id + ', start_job:true) {id}}').then(_r => ref.current.update(true))
  }
  const stopJob = (id) => {
    client.mutate('{channelUpdate(id:' + id + ', cancel_job:true) {id}}').then(_r => ref.current.update(true))
  }

  const client = new graphQLApi(useAuthDispatch());

  useEffect(() => {
    const timer = setInterval(() => {
      const rows = ref.current.getRows().map(r => r.job_id !== null);
      ref.current.fetchRows().then(_r => setIds(ids.filter(i => !rows.includes(i)))
    );
    }, 5000);
    return () => {clearInterval(timer)};
  }, []);

  return (
    <Paper>
      <EnhancedTable
        {...props}
        ref={ref}
        title={intl.formatMessage({id: 'channels.list.table.heading', defaultMessage: "Channels"})}
        columns={columns}
        query="channels"
        mutations="channel"
        fields='id title source_connector{title} destination_connector{title} job_id progress progress_total last_job_started_at'
        actions={[
          {
            icon: (row) => row.job_id !== null ? <Stop style={{height: 20, width: 20}}/> : <PlayArrow style={{height: 20, width: 20}}/>,
            disabled: (row) => ids.includes(row.id),
            tooltip: intl.formatMessage({id: 'channels.list.action.handle', defaultMessage: 'Handle'}),
            onClick: (row) => row.job_id !== null ? stopJob(row.id) : startJob(row.id),
          },
          {
            icon: FileCopy,
            tooltip: intl.formatMessage({id: 'channels.list.action.copy', defaultMessage: 'Copy'}),
            onClick: row => {
              ref.current.isLoading(true)
              client.mutate('($id:ID!) {channelCopyCreate(id:$id) {id}}',
                {id:  parseInt(row.id)}).then(r => {
                if (r) {
                  ref.current.update();
                }
              });
            }
          }
          ]}
        icon={<SwapHoriz/>}
        urlState
      />
    </Paper>
  );
}
