import React, {useEffect, useState} from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import {useIntl} from "react-intl";
import {useEditor} from "@craftjs/core";
import {graphQLApi} from "../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../contexts/Auth";
import LanguageSelector from "../../../components/Languages/LanguageSelector";
import {useNavigate} from "react-router-dom";

const PdfLayoutTopbar = ({id}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {query, actions} = useEditor();
  const [message, setMessage] = useState();
  const noErrors = {
    title: [],
    entity_type_id: [],
    language_id: [],
    layout: [],
  };
  const [errors, setErrors] = useState(noErrors);
  const [data, setData] = useState({
    id: null,
    title: "",
    entity_type_id: null,
    language_id: '0',
    layout: null,
  });
  const [entityTypes, setEntityTypes] = useState([{id: '2', title: "Kreditor"}]);
  const client = new graphQLApi(useAuthDispatch(), null, {
    handleErrors: validation => {
      console.log(validation)
      if (Array.isArray(validation) && validation[0]?.hasOwnProperty('extensions') && validation[0].extensions.hasOwnProperty('validation')) {
        setErrors({...errors, ...validation[0].extensions.validation});
      }
    }
  });

  useEffect(() => {
    if (id) {
      client.query('{printoutLayouts(filter:{id:' + id + '}){data{id title entity_type{id title} language{id name} layout}}}').then(r => {
        if (r?.hasOwnProperty('printoutLayouts')) {
          let d = r.printoutLayouts.data[0];
          if (d) {
            if (d.entity_type) {
              setEntityTypes([d.entity_type]);
            }
            setData({
              id: d.id,
              title: d.title,
              entity_type_id: d.entity_type?.id || null,
              language_id: d.language?.id || '0',
            });
            if (d.layout && d.layout !== '{}') {
              actions.deserialize(d.layout);
            }
          }
        }
      });
    }
  }, []);

  const save = (goBack) => {
    const vars = {
      title: 'String!',
      entity_type_id: 'ID!',
      language_id: 'ID',
      layout: 'String',
    };
    const layout = {...data, layout: query.serialize()};
    if (id) {
      vars.id = 'ID!';
      layout.id = id;
    }
    const valid = {};
    if (!layout.entity_type_id) {
      valid.entity_type_id = [intl.formatMessage({
        id: "layouts.pdfs.require.entity_type",
        defaultMessage: "Entity type is required"
      })];
    }
    if (!layout.title?.length) {
      valid.title = [intl.formatMessage({id: "layouts.pdfs.require.title", defaultMessage: "A title is required"})];
    }
    if (valid.entity_type_id || valid.title) {
      setErrors({...noErrors, ...valid});
      return;
    }
    setErrors(noErrors);
    client.mutation('printoutLayout', vars, layout, 'id').then(r => {
      if (r?.response) {
        if (goBack) {
          navigate('/admin/pdfs');
          return;
        }
        setMessage(intl.formatMessage({id: "layouts.pdfs.saved", defaultMessage: "The PDF layout was saved"}))
        if (!id) {
          navigate('/admin/pdfs/' + r.response.id, {replace: true});
        }
      }
    });
  }

  return <Paper>
    <Snackbar
      autoHideDuration={7500}
      anchorOrigin={{horizontal: "center", vertical: "top"}}
      message={message}
      open={!!message}
      onClose={_ => setMessage(null)}
    />
    <Grid container spacing={2} style={{padding: "0.25rem 1.5rem"}}>
      <Grid item xs={12}>
        <Typography style={{fontSize: 22}} color="primary" variant="body2" component="div">
          <Grid container>
            <Grid item>
              {id
                ? intl.formatMessage({id: "menu.pdf-layouts.edit"})
                : intl.formatMessage({id: "menu.pdf-layouts.create"})
              }
            </Grid>
          </Grid></Typography>
      </Grid>
      <Grid item xs style={{flexGrow: 10}}>
        <TextField
          error={errors.title?.length > 0}
          fullWidth
          value={data.title}
          onChange={e => setData({...data, title: e.target.value})}
          label={intl.formatMessage({id: "layouts.pdfs.title", defaultMessage: "Title"})}
          helperText={errors.title?.join(' ')}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined" size="small" error={errors.entity_type_id?.length > 0}>
          <InputLabel htmlFor="entity_type_id">{intl.formatMessage({
            id: "layouts.pdfs.entity-type",
            defaultMessage: "Entity type"
          })}</InputLabel>
          <Select
            inputProps={{
              name: 'entity_type_id',
              id: 'entity_type_id',
            }}
            value={data.entity_type_id}
            onOpen={_ => {
              setEntityTypes([{
                id: "--loading--",
                title: intl.formatMessage({id: "common.loading", defaultMessage: "Loading..."})
              }]);
              client.query('{entityTypes{data{id title}}}').then(r => {
                if (r?.hasOwnProperty('entityTypes')) {
                  setEntityTypes(r.entityTypes.data);
                }
              });
            }}
            onChange={e => setData({...data, entity_type_id: e.target.value})}
          >{entityTypes.map(e =>
            <MenuItem key={"entity-type-" + e.id} disabled={e.id === "--loading--"}
                      value={e.id}>{e.title}</MenuItem>
          )}</Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <LanguageSelector value={data.language_id} onChange={v => setData({...data, language_id: v})}/>
      </Grid>
      <Grid item xs style={{flexGrow: 1, textAlign: "right"}}>
        <Button
          variant="contained"
          color="primary"
          onClick={_ => save(false)}
        >{intl.formatMessage({id: "common.button.save"})}</Button>
      </Grid>
      <Grid item xs style={{flexGrow: 1, textAlign: "right"}}>
        <Button
          variant="contained"
          color="primary"
          onClick={_ => save(true)}
          style={{whiteSpace: "nowrap"}}
        >{intl.formatMessage({id: "common.button.save_and_close"})}</Button>
      </Grid>
    </Grid>
  </Paper>
};
export default PdfLayoutTopbar;
