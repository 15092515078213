import React, {useState} from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid, MenuItem, Paper, TextField} from "@material-ui/core";
import {Dns, Domain} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import moment from "moment";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {useParams} from "react-router-dom";

export default function SiteEdit(props) {
  const intl = useIntl();
  const params = useParams();
  const [extraInfo, setExtraInfo] = useState(null);

  let id = Number(params.id);

  const fields = [
    {
      column: 1,
      field: "name",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.name",
        defaultMessage: "Name",
      }),
      input: "text",
    },
    {
      column: 1,
      field: "package",
      initial: "premium",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.package",
        defaultMessage: "Package",
      }),
      options: [
        {id: "starter", name: "Starter"},
        {id: "standard", name: "Standard"},
        {id: "premium", name: "Premium"},
        {id: "enterprise", name: "Enterprise"},
        {id: "openconnect", name: "OpenConnect"},
        {id: "template", name: "Template"},
      ]
    },
    {
      column: 1,
      field: "reference",
      initial: "-",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.reference",
        defaultMessage: "Reference",
      }),
      input: "text",
    },
    {
      column: 1,
      field: "is_active",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({
        id: "sites.edit.label.is_active",
        defaultMessage: "Is Active",
      }),
      input: "switch",
    },
    {
      column: 1,
      field: "demo_expire_at",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.demo_expire_at",
        defaultMessage: "Demo expires at",
      }),
      input: "date",
    },
    {
      column: 1,
      field: "support",
      type: "String",
      initial: "",
      label: "",
      render: (key, data, setData) => {
        return <TextField
          label={intl.formatMessage({
            id: "sites.edit.label.support",
            defaultMessage: "Your current support agreement",
          })}
          value={data['support'] ? data['support'] : data['support_agreements'] ? data['support_agreements'][0].id : ''}
          onChange={e => setData({...data, support: e.target.value})}
          select={!!data['support_agreements']}
          fullWidth
        >
          {data['support_agreements']?.map(a => <MenuItem key={'support_' + a.id} value={a.id}>{a.Titel}</MenuItem>)}
        </TextField>
      },
    },
    {
      column: 2,
      field: "company",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.company",
        defaultMessage: "Company name",
      }),
    },
    {
      column: 2,
      field: "address",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.address",
        defaultMessage: "Address",
      }),
    },
    {
      column: 2,
      field: "zip",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.zip",
        defaultMessage: "Zip",
      }),
    },
    {
      column: 2,
      field: "city",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.city",
        defaultMessage: "City",
      }),
    },
    {
      column: 2,
      field: "country",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.country",
        defaultMessage: "Country",
      }),
    },
    {
      column: 2,
      field: "logo",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.logo",
        defaultMessage: "Logo",
      }),
      input: "file",
    },
    {
      column: 3,
      field: "cvr",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.cvr",
        defaultMessage: "VAT reg.",
      }),
    },
    {
      column: 3,
      field: "invoice_email",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.invoice_email",
        defaultMessage: "Invoice e-mail",
      }),
    },
    {
      column: 3,
      field: "contact",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.contact",
        defaultMessage: "Contact",
      }),
    },
    {
      column: 3,
      field: "phone",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.phone",
        defaultMessage: "Telephone",
      }),
    },
    {
      column: 3,
      field: "email",
      initial: "",
      type: "String",
      label: intl.formatMessage({
        id: "sites.edit.label.email",
        defaultMessage: "E-mail",
      }),
    },
  ];

  const domainColumns = [
    {
      title: intl.formatMessage({
        id: "domains.list.column.name",
        defaultMessage: "Name",
      }),
      field: "name",
    },
    {
      title: intl.formatMessage({
        id: "domains.list.column.is_active",
        defaultMessage: "Is Active",
      }),
      field: "is_active",
      type: "boolean",
      initial: false,
    },
    {
      title: intl.formatMessage({
        id: "domains.list.column.is_verified",
        defaultMessage: "Is Verified",
      }),
      field: "is_verified",
      type: "boolean",
      initial: false,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<Domain/>}
            title={intl.formatMessage({id: "sites.edit.heading"})}/>
          <CardContent>
            <GraphQLEditForm
              {...props}
              id={id}
              query={"sites"}
              mutations={"site"}
              fields={fields}
              cols={3}
              extraFields={" support schema created_at order_sent_at settings"}
              extraQueries={"support_agreements:supportAgreements{id Titel}"}
              extraResults={["support_agreements"]}
              buttons={[
                {
                  label: intl.formatMessage({id: "sites.edit.view_extras", defaultMessage: "Extra info"}),
                  onClick: (_e, data, _setData) => setExtraInfo(extraInfo ? null : data),
                  color: "primary",
                },
                {
                  label: intl.formatMessage({id: "sites.edit.payment_received", defaultMessage: "Payment received"}),
                  onClick: (_e, data, setData) => setData({...data, demo_expire_at: null}),
                  color: "secondary",
                  variant: "outlined"
                }
              ]}
            />
            {extraInfo && <Grid container>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.schema",
                defaultMessage: "DB Schema"
              })}:</Grid>
              <Grid item xs={10}>{extraInfo.schema}</Grid>
              <Grid item xs={12} style={{borderBottom: "1px solid lightgrey"}}></Grid>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.created_at",
                defaultMessage: "Created at"
              })}:</Grid>
              <Grid item
                    xs={10}>{extraInfo.created_at && moment(extraInfo.created_at).format(intl.formatMessage({id: "common.datetime.format"}))}</Grid>
              <Grid item xs={12} style={{borderBottom: "1px solid lightgrey"}}></Grid>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.order_sent_at",
                defaultMessage: "Order sent at"
              })}:</Grid>
              <Grid item
                    xs={10}>{extraInfo.order_sent_at && moment(extraInfo.order_sent_at).format(intl.formatMessage({id: "common.datetime.format"}))}</Grid>
              <Grid item xs={12} style={{borderBottom: "1px solid lightgrey"}}></Grid>
              <Grid item xs={2}>{intl.formatMessage({
                id: "sites.edit.info.settings",
                defaultMessage: "App Settings"
              })}:</Grid>
              <Grid item xs={10}><small><tt>{extraInfo.settings}</tt></small></Grid>
            </Grid>}
          </CardContent>
        </Card>
      </Grid>
      {id ?
        <Grid item xs={12}>
          <Paper>
            <EnhancedTable
              {...props}
              title={intl.formatMessage({
                id: "domains.list.table.heading",
                defaultMessage: "Domains list",
              })}
              columns={domainColumns}
              query="domains"
              mutations="domain"
              filter={"site_id:" + id}
              fields="id site{name} name is_active is_verified"
              icon={<Dns/>}
              options={{
                pageSize: 10,
              }}
              baseUrl={"/admin/sites/" + id + "/domains"}
            />
          </Paper>
        </Grid>
        : ""}
    </Grid>
  );
}
