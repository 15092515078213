import React from 'react';
import {useIntl} from 'react-intl';
import {Paper} from '@material-ui/core';
import {Extension} from '@material-ui/icons';
import EnhancedTable from '../../../../components/DataTable/EnhancedTable';

export default function FieldList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'fields.list.column.name', defaultMessage: 'Name'}), field: 'name'},
    {title: intl.formatMessage({id: 'fields.list.column.type', defaultMessage: 'Type'}), field: 'type_title'},
    {title: intl.formatMessage({id: 'fields.list.column.is_searchable', defaultMessage: 'Searchable'}), field: 'is_searchable', type: 'boolean'},
    {title: intl.formatMessage({id: 'fields.list.column.is_editable', defaultMessage: 'Editable'}), field: 'is_editable', type: 'boolean'},
    {title: intl.formatMessage({id: 'fields.list.column.usage_count', defaultMessage: '# values'}), field: 'usage_count', type: 'number', align:'right'},
    {title: intl.formatMessage({id: 'fields.list.column.created_at', defaultMessage: 'Created at'}), field: 'created_at',}
  ];
  if (!props.filter) {
    columns.splice(2, 0,
      {
        title: intl.formatMessage({id: 'entity_type_layouts.list.column.entity_type', defaultMessage: 'Entity type'}),
        field: 'entity_type.title'
      });
  }
  const {elevation = 2, margin = 3} = props;

  return (<Paper elevation={elevation} style={{margin: margin}}>
      <EnhancedTable
        title={intl.formatMessage({id: 'fields.list.table.heading', defaultMessage: "Fields list"})}
        columns={columns}
        query="fields"
        mutations="field"
        fields='id entity_type{title} type type_title name key uses_languages is_searchable is_editable usage_count field_groups{id name} created_at'
        icon={<Extension/>}
        urlState
        {...props}
      />
    </Paper>
  );
}
