import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Button, Card, CardContent, CardHeader, CircularProgress, Grid, TextField} from '@material-ui/core';
import {graphQLApi} from '../../../../services/GraphQLApi';
import {useAuthDispatch} from '../../../../contexts/Auth';
import {Save} from '@material-ui/icons';
import FilterEdit from '../../../../components/Filters/FilterEdit';
import RoleSelector from '../../Settings/RoleSelector';
import {useLocation, useNavigate, useParams} from "react-router-dom";

export default function ListingEdit(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const id = props.id ? props.id : Number(params.id);
  const client = new graphQLApi(useAuthDispatch());
  const [isLoading, setIsLoading] = useState(false);

  const [listing, setListing] = useState({
    title: '',
    comparator: 'and',
    entity_type_id: props.entityTypeId,
    listing_operands: [],
    roles: [],
    field: {},
  });
  const [entityTypes, setEntityTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const [roles, setRoles] = useState([]);

  const listingQueryFields = 'id title comparator listing_operands{id field_id field{id name type field_options{id title}} column operator value} entity_type_id roles{id}';
  useEffect(() => {
    setIsLoading(true);
    let query = ' roles{data{id title}}' +
      ' fields(sorting:"name",direction:"asc"){data{' +
      '   id name type entity_type{id title} field_options{id title}' +
      ' }} ';
    if (!props.entityTypeId) {
      query += ' entityTypes{data{id title}}';
    }
    if (id > 0) {
      query = query + ' listings(filter:{id:' + id + '}){data{' + listingQueryFields + '}}';
    }
    client.query('{' + query + '}').then(resp => {
      if (resp?.hasOwnProperty('entityTypes')) {
        setEntityTypes(resp.entityTypes.data);
      }
      if (resp?.hasOwnProperty('fields')) {
        setFields(resp.fields.data);
      }
      if (resp?.hasOwnProperty('roles')) {
        setRoles(resp.roles.data);
        if (!Number(id)) {
          setListing({...listing, roles: resp.roles.data})
        }
      }
      if (resp?.hasOwnProperty('listings') && resp?.hasOwnProperty('roles')) {
        setListing({...resp.listings.data[0], roles: resp.listings.data[0]?.roles ? resp.listings.data[0].roles : []});
      }
      setIsLoading(false);
    });
  }, []);

  const saveListing = () => {
    let vars = {
      entity_type_id: 'ID',
      title: 'String',
      comparator: 'String',
      listing_operands: '[FilterOperandInput]',
      roles: '[ID]',
    };
    let data = {...listing};
    if (props.entityTypeId) {
      data.entity_type_id = props.entityTypeId;
    }
    if (id > 0) {
      vars.id = 'ID!';
    }
    if (data.user) {
      data.user_id = data.user.id;
      vars.user_id = 'ID';
    }
    data.roles = data.roles.filter(r => !!r);
    data.listing_operands = listing.listing_operands.map(f => ({
      id: RegExp('^new-[0-9]+$').test(f.id) ? null : f.id,
      field_id: f.field_id,
      operator: f.operator,
      value: f.value,
    }));
    setIsLoading(true);
    client.mutation('listing', vars, data, listingQueryFields).then(resp => {
      if (resp?.hasOwnProperty('response')) {
        setListing({...resp.response, roles: resp.response.roles ? resp.response.roles : []});
        if (props.entityTypeId) navigate(-1);
        else navigate(location.pathname.replace('/create', '/' + resp.response.id), {replace: true});
      }
      setIsLoading(false);
    });
  };

  const handleChange = d => {
    setListing(d);
    if (props.setModified) props.setModified(true);
  }
  const {elevation = 2, margin = 3} = props;
  return <Card style={{margin: margin}} elevation={elevation}>
    <CardHeader title={intl.formatMessage({
      id: "listings.edit.heading",
      defaultMessage: "Edit filter"
    })}/>
    <CardContent>
      <Grid container>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({
                  id: 'listings.edit.label.title',
                  defaultMessage: 'Title',
                })}
                value={listing.title}
                onChange={e => handleChange({...listing, title: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterEdit
                entityTypeId={props.entityTypeId}
                filter={listing}
                setFilter={handleChange}
                operands={listing.listing_operands}
                setOperands={v => handleChange({...listing, listing_operands: v})}
                entityTypes={entityTypes}
                fields={fields}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <RoleSelector roles={roles} data={listing} setData={handleChange}/>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end" style={{gap: 8, marginTop: 16}}>
          <Button variant="outlined"
                  onClick={_e => navigate(-1)}>{intl.formatMessage({id: 'common.button.back'})}</Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={isLoading ? <CircularProgress size={20}/> : <Save/>}
            disabled={isLoading || !listing.title || !listing.entity_type_id || !listing.roles.length}
            onClick={saveListing}
          >{intl.formatMessage({id: 'common.button.save'})}</Button>
        </Grid>
      </Grid>
    </CardContent>
  </Card>;
}
