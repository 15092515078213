import React, {useContext} from "react";
import {useIntl} from "react-intl";
import moment from "moment";
import {FieldTypes, getEntityValueFromField} from "../../../variables/fields";
import {Chip, Icon, Typography} from "@material-ui/core";
import {renderThumbNails} from "../../../components/FileTypes";
import {EntityContext} from "../EntityLayoutEditor";
import Box from "@material-ui/core/Box";
import {getEntityTitle} from "./FieldTypes/Field";
import {LanguageContext} from "../../../contexts/Layouts";

export default function RenderEntityValue({entityValues, entity, field, prepend = '', fields, entityTypes, ...rest}) {
  const {getValuesOfField} = useContext(EntityContext);
  const {language} = useContext(LanguageContext);
  const intl = useIntl();

  const compareValueField = (value) => {
    return (value.field_id || value.field?.id) === field.id
      && (!field.uses_languages || value.language_id === language.id);
  }

  let pVal;
  if (entityValues) {
    pVal = entityValues?.length ? entityValues[0] : null;
  } else if (entity) {
    if (Array.isArray(entityValues)) {
      if (typeof field === "string") {
        pVal = entityValues[field];
      } else {
        pVal = entityValues ? entityValues.find(compareValueField) : null;
      }
      entityValues = entity.values.filter(compareValueField);
    } else {
      entityValues = entity.values.filter(compareValueField);
      pVal = entityValues?.length > 0 ? entityValues[0] : null;
    }
  } else {
    entityValues = getValuesOfField(field.id);
  }
  if (!pVal) return null;

  let m = field?.type.match(/^entit(y|ies)_(\d)+$/);
  if (m) {
    let entityType = entityTypes.find(et => et.id === m[2]);
    let entityTypeFieldIds = [];
    ['number', 'title', 'third', 'fourth', 'fifth', 'sixth'].forEach(k => {
      if (entityType[k + "_field"]) entityTypeFieldIds.push(entityType[k + "_field"].id);
    });
    return entityValues.sort((a, b) => {
      let av = getEntityValueFromField(fields[entityTypeFieldIds[0]], a.related_entity?.values.find(v => v.field_id === entityTypeFieldIds[0]), language.id);
      let bv = getEntityValueFromField(fields[entityTypeFieldIds[0]], b.related_entity?.values.find(v => v.field_id === entityTypeFieldIds[0]), language.id);
      if (typeof av === "string") {
        return av.localeCompare(bv, language.locale, {sensitivity: 'base'});
      } else {
        if (av < bv) return -1;
        if (av > bv) return 1;
        return 0;
      }
    }).map(v => <Typography variant={"inherit"}
                            display="block">{getEntityTitle(v.related_entity, entityTypeFieldIds, fields)}</Typography>);
  }
  switch (field?.type) {
    case FieldTypes.BOOLEAN:
      return <Icon style={{color: pVal.bool ? "green" : "red"}} fontSize={"inherit"} {...rest}>circle</Icon>;
    case FieldTypes.DATE:
      return <Typography
        variant="inherit" {...rest}>{prepend}{moment(pVal.date).format(intl.formatMessage({id: "common.date.format"}))}</Typography>;
    case FieldTypes.DATETIME:
      return <Typography
        variant="inherit" {...rest}>{prepend}{moment(pVal.datetime).format(intl.formatMessage({id: "common.datetime.format"}))}</Typography>;
    case FieldTypes.STRING:
      return <Typography variant="inherit" {...rest}>{prepend}{pVal.string}</Typography>;
    case FieldTypes.TEXT:
      return <Typography variant="inherit" {...rest} style={{whiteSpace: "pre"}}>{prepend}{pVal.text}</Typography>;
    case FieldTypes.HTML:
      return <Box {...rest} whiteSpace="wrap" dangerouslySetInnerHTML={{__html: pVal.text || ''}}/>;
    case FieldTypes.PRICE:
      return <Typography variant="inherit" {...rest}>{prepend}{intl.formatNumber(pVal.decimal, {
        style: 'currency',
        currency: pVal.string,
        currencyDisplay: 'code',
        minimumFractionDigits: 2,
      })}</Typography>;
    case FieldTypes.DECIMAL:
      return <Typography variant="inherit" {...rest}>{prepend}{intl.formatNumber(pVal.decimal)}</Typography>;
    case FieldTypes.NUMBER:
      return <Typography variant="inherit" {...rest}>{prepend}{pVal.integer}</Typography>;
    case FieldTypes.OPTION:
      return <Typography
        variant="inherit" {...rest}>{prepend}{pVal.field_option ? pVal.field_option.title : ''}</Typography>;
    case FieldTypes.ASSET:
      return renderThumbNails(pVal);
    case FieldTypes.ASSETS:
      return !Array.isArray(entityValues) ? '' : entityValues.map((pv, pvIdx) => renderThumbNails(pv, pvIdx));
    case FieldTypes.OPTIONS:
      return !Array.isArray(entityValues) ? '' : entityValues.filter(pv => !!pv.field_option).map((pv, pvIdx) =>
        <Chip key={"column-" + field.id + "-option-" + pvIdx} size={"small"}
              style={{marginLeft: 3}} label={pv.field_option.title}/>)
    default:
      return '';
  }
}
