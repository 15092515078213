import {authHeader, authSignOut, authUpdateToken, getApiDomain, isApiErrorShown} from 'contexts/Auth';
import moment from 'moment';
import axios from 'axios';

window.apiWorking = false;
class transport {
  constructor(endpoint, options) {
    this.options = options;
    this.endpoint = endpoint;
    this.http = axios.create({
      ...this.options,
      baseURL: endpoint,
      // To pass cookies to the server. (supports CORS as well)
      credentials: "include",
    });
  }

  async send(query, variables, operationName) {
    let payload = {
      query: query,
      variables: variables,
      operationName: operationName,
    };
    let config = {
      headers: {
        "Accept-Language": window.language,
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader(),
      },
    };
    if (this.options.onUploadProgress) {
      config.onUploadProgress = this.options.onUploadProgress;
    }
    let _this = this;
    window.apiWorking = true;
    return this.http.post('', payload, config)
        .catch(function (e) {
          const response = e.response;
          console.dir(e);
          if (response.status === 406 && response.data?.error === 'in_active') {
            _this.options.dispatch({type: "LOGIN_FAILURE"});
          }
          if (!isApiErrorShown()) {
            _this.options.dispatch({type: "API_ERROR", status: response.status, msg: response.statusText});
          }
          return response;
        })
      .then(function (response) {
        if (
          response.headers.hasOwnProperty("Authorization") &&
          !authUpdateToken(response.headers.Authorization)
        ) {
          console.error("Failed to update token from header", response.headers);
          return Promise.reject("Unable to update token from header");
        }
        // 200 is for success
        // 400 is for bad request
        if (response.status !== 200 && response.status !== 400) {
          if (response.status === 401) {
            authSignOut(_this.options.dispatch);
          }
          else if (!isApiErrorShown()) {
            _this.options.dispatch({ type: "API_ERROR", status: response.status, msg: response.statusText })
          }
          return Promise.reject("Invalid status code: " + response.status);
        }
        else if (isApiErrorShown()) {
          _this.options.dispatch({ type: "API_OK" })
        }
        window.apiWorking = false;
        let json = response.data;
        if (json.code)
          console.error("Found error code in GraphQL response", json);
        if (Number(json.code) === 103) {
          //authSignOut(_this.options.dispatch);
          return Promise.reject(json.message);
        }
        var data = json.data;
        var errors = json.errors;

        if (errors && _this.options.handleErrors) {
          _this.options.handleErrors(errors, data);
          return null;
        }

        return data;
      });
  }
}

class graphQLApi {
  constructor(dispatch, onError = null, options = {}) {
    this.onError = onError;
    this.dispatch = dispatch;
    this.options = {
      handleErrors: typeof onError === "function" ? onError : this.handleErrors,
      dispatch: dispatch,
      ...options,
    };
    this.client = null;
  }

  getClient() {
    if (this.client === null) {
      try {
        const Lokka = require("lokka").Lokka;
        this.client = new Lokka({
          transport: new transport(getApiDomain() + "/graphql", this.options),
        });
      } catch (e) {
        console.error(e);
        //authSignOut(this.dispatch);
        if (!isApiErrorShown()) {
          this.options.dispatch({type: "API_ERROR", status: 500, msg: e.message});
        }
        return null;
      }
    }
    return this.client;
  }

  tableQuery(tableState, columns, query, fields = {}, filter = "", args = "") {
    if (args.length) args += ",";
    args += "page:" + (tableState.page + 1);
    args += ",limit:" + tableState.pageSize;
    if (tableState.orderBy)
      args += ",sorting:" + JSON.stringify(tableState.orderBy.field);
    if (tableState.orderDirection)
      args += ",direction:" + JSON.stringify(tableState.orderDirection);
    tableState.filters.forEach((element) => {
      filter += (filter.length ? "," : "") + element.column.field + ":";
      //console.log('Setting query filter', element.column.field, element.column.tableData.columnOrder, columns[element.column.tableData.columnOrder]);
      switch (columns[element.column.tableData.columnOrder].type) {
        case "boolean":
          filter += element.value === "checked" ? "true" : "false";
          break;
        case "date":
          filter =
            filter.substr(0, filter.length - 1) +
            '_date:"' +
            moment(element.value).format("YYYY-MM-DD") +
            '"';
          break;
        case "numeric":
          filter += Number(element.value);
          break;
        default:
          filter += JSON.stringify(element.value);
      }
    });
    if (tableState.search && tableState.search.length > 0) {
      filter +=
        (filter.length ? "," : "") + "q:" + JSON.stringify(tableState.search);
    }
    if (filter.length) args += ",filter:{" + filter + "}";
    return this.query(
      `{ ${query}(${args}) { data { ${fields} } total current_page } }`
    );
  }

  mutation(name, variables, data, fields) {
    let varsDef = [];
    let inputs = [];
    let d = {};
    for (let i in variables) {
      varsDef.push('$' + i + ':' + variables[i]);
      inputs.push(i + ':$' + i);
      if (variables[i] === "Int") {
        d[i] = parseInt(data[i]);
      }
      else if (variables[i] === "Float") {
        d[i] = parseFloat(data[i]);
      }
      else if (variables[i] === "ID" || variables[i] === "ID!") {
        let dataKey = i;
        if (data[i] === undefined) {
          dataKey = i.replace(/_id$/, "");
        }
        d[i] = (typeof data[dataKey] === "object" && data[dataKey] && data[dataKey].id > 0) ? parseInt(data[dataKey].id) : (data[dataKey] > 0 ? parseInt(data[dataKey]) : null);
      }
      else if ((variables[i] === "[ID]" || variables[i] === "[ID]!") && Array.isArray(data[i]) && typeof data[i][0] === "object") {
        d[i] = data[i].map(v => parseInt(v.id));
      }
      else
        d[i] = data[i];
    }
    return this.mutate('('+varsDef.join(',')+')' +
      '{' +
      'response: '+ name + (data.id ? 'Update' : 'Create') + '(' + inputs.join(',') + ')' +
      '{' + fields + '}' +
      '}', d);
  }

  query(query, vars = {}) {
    return this.getClient()
      .query(query, vars)
      .catch((e) => {
        this.handleException(e);
      });
  }

  mutate(query, vars = {}) {
    return this.getClient()
      .mutate(query, vars)
      .catch((e) => {
        this.handleException(e);
      });
  }

  handleException(e) {
    console.error("GraphQL API caught an exception", e);
    //authSignOut(this.dispatch);
    if (!isApiErrorShown()) {
      this.options.dispatch({type: "API_ERROR", status: 500, msg: e.message});
    }
  }

  handleErrors(errors, data) {
    console.debug("Error data", data);
    console.error("GraphQL Error caught", errors, this);
    if (this.onError) {
      for (let i in errors) {
        if (errors[i].extensions.hasOwnProperty("validation")) {
          this.onError(errors[i].extensions.validation);
        } else {
          alert("GraphQL API Error: " + errors[i].message);
        }
      }
    }
  }
}

function graphQLReduceFields(fields = {}, returns = "initial") {
  let o = {};
  let a = [];
  let s = "";
  for (let i in fields) {
    if (fields[i].input === "heading") continue;
    switch (returns) {
      case "initial":
        if (fields[i].key) {
          if (o[fields[i].field] === undefined) {
            o[fields[i].field] = {};
          }
          o[fields[i].field][fields[i].key] = fields[i].initial;
        } else {
          o[fields[i].field] = fields[i].initial;
        }
        break;
      case "vars":
        s = fields[i].field;
        switch (fields[i].type) {
          default:
            s += ":$" + fields[i].field;
        }
        a.push(s);
        break;
      case "fields":
        if (fields[i].no_fetch !== true) {
          switch (fields[i].type) {
            case "Json":
            case "[ID]":
            case "ID":
              s = fields[i].field.replace("_id", "") + "{ id ";
              if (Array.isArray(fields[i].titleField)) {
                s += fields[i].titleField
                  .map((v) => {
                    if (v.indexOf(".") !== -1) {
                      let temp = v.split(".");
                      return temp.join("{id ") + "}".repeat(temp.length - 1);
                    } else return v;
                  })
                  .join(" ");
              } else if (fields[i].titleField.indexOf(".") !== -1) {
                s += fields[i].titleField.replace(".", " { ") + " } ";
              } else {
                s += fields[i].titleField;
              }
              if (fields[i].extraFields) {
                s += " " + fields[i].extraFields;
              }
              s += " }";
              break;
            default:
              s = fields[i].field + " ";
          }
          if (fields[i].input === "file") {
            a.push(s);
            s = fields[i].field + "_uri ";
          }
          a.push(s);
        }
        break;
      case "vars_def":
        s = "$" + fields[i].field;
        switch (fields[i].type) {
          case "Json":
          case "Email":
            s += ":String";
            break;
          default:
            s += ":" + fields[i].type;
        }
        if (fields[i].required) {
          s += "!";
        }
        a.push(s);
        break;
      case "variables":
        s = "";
        switch (fields[i].type) {
          case "Json":
          case "Email":
            s = "String";
            break;
          default:
            s = fields[i].type;
        }
        if (fields[i].required) {
          s += "!";
        }
        o[fields[i].field] = s;
        break;
      case "validation":
        o[fields[i].field] = [];
        break;
      default:
    }
  }
  switch (returns) {
    case "validation":
    case "initial":
    case "variables":
      return o;
    case "fields":
      return a.join(" ");
    case "vars":
    case "vars_def":
      return a.join(", ");
    default:
  }
}

export { graphQLReduceFields, graphQLApi };
