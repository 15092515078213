import React, {useState} from 'react';
import moment from 'moment/moment';
import {
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {Add, Check, Delete, Edit} from '@material-ui/icons';
import {FieldTypes, stringFieldTypes} from 'variables/fields';
import {useIntl} from 'react-intl';

export default function FilterEdit(props) {
  const {
    filter,
    setFilter,
    operands,
    setOperands,
    entityTypes,
    fields,
  } = props;
  const intl = useIntl();

  const operators = [
    {value: 'like', title: intl.formatMessage({id: 'products.sidebar.operator.contains', defaultMessage: 'Contains'})},
    {
      value: 'not like',
      title: intl.formatMessage({id: 'products.sidebar.operator.does_not_contain', defaultMessage: 'Does not contain'}),
    },
    {
      value: 'like starts',
      title: intl.formatMessage({id: 'products.sidebar.operator.starts_with', defaultMessage: 'Starts with'}),
    },
    {
      value: 'not like starts',
      title: intl.formatMessage({id: 'products.sidebar.operator.not_starts_with', defaultMessage: 'Does not start with'}),
    },
    {
      value: 'like ends',
      title: intl.formatMessage({id: 'products.sidebar.operator.ends_with', defaultMessage: 'Ends with'}),
    },
    {
      value: 'not like ends',
      title: intl.formatMessage({id: 'products.sidebar.operator.not_ends_with', defaultMessage: 'Does not end with'}),
    },
    {value: '=', title: intl.formatMessage({id: 'products.sidebar.operator.equal', defaultMessage: 'Equal to'})},
    {
      value: '!=',
      title: intl.formatMessage({id: 'products.sidebar.operator.not_equal', defaultMessage: 'Not equal to'}),
    },
    {value: '<', title: intl.formatMessage({id: 'products.sidebar.operator.less', defaultMessage: 'Less than'})},
    {
      value: '<=',
      title: intl.formatMessage({id: 'products.sidebar.operator.less_or_equal', defaultMessage: 'Less or equal'}),
    },
    {value: '>', title: intl.formatMessage({id: 'products.sidebar.operator.greater', defaultMessage: 'Greater than'})},
    {
      value: '>=',
      title: intl.formatMessage({id: 'products.sidebar.operator.greater_or_equal', defaultMessage: 'Greater or equal'}),
    },
  ];

  const [editingOperand, setEditingOperand] = useState(null);

  const handleOperandChange = (property, value) => {
    const ops = [...operands];
    const index = ops.findIndex(o => o.id === editingOperand);
    if (index === -1) {
      console.error('Did not find the operand id to change', editingOperand, ops);
      return false;
    }
    if (property === 'field') {
      const field = fields.find(f => f.id === value);
      ops[index].field_id = field?.id;
      ops[index].field = field;
    }
    else {
      ops[index][property] = value;
    }
    setOperands(ops);
  };

  const handleDeleteOperand = (operandId) => {
    setOperands(operands.filter(o => o.id !== operandId));
  };

  return <Grid container spacing={1} justifyContent={"flex-end"}>
    <Grid item xs={12}><strong>Filter opsætning</strong></Grid>
    {!props.entityTypeId && <Grid item style={{flexGrow: 1}}>
      <TextField
        fullWidth
        disabled={operands.length}
        label={intl.formatMessage({
          id: 'listings.edit.label.entity_type',
          defaultMessage: 'Entity Type',
        })}
        value={filter.entity_type_id}
        onChange={e => {
          setFilter({...filter, entity_type_id: e.target.value, listing_operands: []});
          setEditingOperand(null);
        }}
        select
        InputLabelProps={{shrink: filter.entity_type_id > 0}}
      >{entityTypes.map(eT =>
        <MenuItem value={eT.id}>{eT.title}</MenuItem>,
      )}</TextField>
    </Grid>}
    <Grid item style={{minWidth: 100}}>
      <TextField
        fullWidth
        select
        label={intl.formatMessage({
          id: 'listings.edit.label.comparator',
          defaultMessage: 'Comparator',
        })}
        value={filter.comparator}
        onChange={e => setFilter({...filter, comparator: e.target.value})}
      >
        <MenuItem value="and">{intl.formatMessage({id: 'listings.edit.values.comparator.and', defaultMessage: 'And'})}</MenuItem>
        <MenuItem value="or">{intl.formatMessage({id: 'listings.edit.values.comparator.or', defaultMessage: 'Or'})}</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <FormLabel>{intl.formatMessage({
          id: 'listings.edit.operands-table.heading',
          defaultMessage: 'Filter',
        })}</FormLabel>
        <TableContainer>
          <Table width="100%" size="small">
            <TableHead>
              <TableRow style={{height: 38}}>
                <TableCell>{intl.formatMessage({id: 'listings.edit.operands-table.column.field', defaultMessage: 'Field'})}</TableCell>
                <TableCell width={125}>{intl.formatMessage({id: 'listings.edit.operands-table.column.operator', defaultMessage: 'Operator'})}</TableCell>
                <TableCell width="35%">{intl.formatMessage({id: 'listings.edit.operands-table.column.value', defaultMessage: 'Value'})}</TableCell>
                <TableCell width={75} align="right">
                  {!editingOperand && <IconButton size="small" onClick={_e => {
                    const newId = 'new-' + moment().unix();
                    setOperands([
                      ...operands,
                      {
                        id: newId,
                        field_id: null,
                        field: null,
                        column: null,
                        operator: null,
                        value: '',
                      },
                    ]);
                    setEditingOperand(newId);
                  }}><Add/></IconButton>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{operands?.map(operand =>
              editingOperand === operand.id
                ? <TableRow style={{height: 39}}>
                  <TableCell>
                    <TextField
                      fullWidth
                      select
                      value={operand.field_id}
                      onChange={e => handleOperandChange('field', e.target.value)}
                    >{fields.filter(f => f.entity_type.id === filter.entity_type_id
                      && !RegExp('^' + FieldTypes.ENTITY + '_[0-9]+$').test(f.type)
                      && !RegExp('^' + FieldTypes.ENTITIES + '_[0-9]+$').test(f.type)
                      && !RegExp('^' + FieldTypes.RELATIONS + '_[0-9]+$').test(f.type),
                    ).map(f =>
                      <MenuItem value={f.id}>{f.name}</MenuItem>,
                    )}</TextField>
                  </TableCell>
                  <TableCell><TextField
                    fullWidth
                    select
                    value={operand.operator}
                    onChange={e => handleOperandChange('operator', e.target.value)}
                  >{operators.filter(o => {
                    if (stringFieldTypes.indexOf(operand.field?.type) !== -1) {
                      return ['=', '!=', 'like', 'not like', 'like starts', 'not like starts', 'like ends', 'not like ends'].indexOf(o.value) !== -1;
                    }
                    else {
                      return ['=', '!=', '<', '<=', '>', '>='].indexOf(o.value) !== -1;
                    }
                  }).map((o, k) =>
                    <MenuItem key={'filter-operator-select-item-' + k} value={o.value}>{o.title}</MenuItem>,
                  )}</TextField>
                  </TableCell><TableCell>{[FieldTypes.OPTION, FieldTypes.OPTIONS].includes(operand.field?.type)
                  ? <TextField
                    fullWidth
                    select
                    value={operand.value}
                    onChange={e => handleOperandChange('value', e.target.value)}
                  >{fields.find(f => f.id === operand.field_id)?.field_options?.map(o =>
                    <MenuItem value={o.id}>{o.title}</MenuItem>,
                  )}</TextField>
                  : <TextField
                    fullWidth
                    value={operand.value}
                    onChange={e => handleOperandChange('value', e.target.value)}
                  />}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={_e => {
                        if (!operand.field_id || !operand.operator) {
                          handleDeleteOperand(editingOperand);
                        }
                        setEditingOperand(null);
                      }}
                    ><Check/></IconButton>
                  </TableCell>
                </TableRow>
                : <TableRow style={{height: 39}}>
                  <TableCell>{operand.field?.name}</TableCell>
                  <TableCell>{operators.find(o => o.value === operand.operator)?.title}</TableCell>
                  <TableCell>{[FieldTypes.OPTION, FieldTypes.OPTIONS].includes(operand.field?.type)
                    ? operand.field.field_options.find(o => o.id === operand.value)?.title
                    : operand.value}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={_e => setEditingOperand(operand.id)}><Edit/></IconButton>
                    <IconButton size="small" onClick={_e => handleDeleteOperand(operand.id)}><Delete/></IconButton>
                  </TableCell>
                </TableRow>,
            )}</TableBody>
          </Table>
        </TableContainer>
      </FormControl>
    </Grid>
  </Grid>;
}
