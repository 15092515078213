import {AppBar, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from 'assets/theme/components/admin-navbar.js';

const useStyles = makeStyles(componentStyles);

export default function ActionBar({children, justifyContent = 'flex-end', ...rest}) {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      color="transparent"
      component="div"
      elevation={4}
      classes={{root: classes.appBarRoot}}
      style={{
        width: "100%",
        boxShadow: 'none',
        top: 51,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        paddingLeft: 266,
      }}
    >
      <Grid
        container
        display='flex'
        // spacing={2}
        justifyContent={justifyContent}
        {...rest}
      >
        {children}
      </Grid>
    </AppBar>
  )
}
