import React, {useContext, useState} from "react";
import {LinearProgress, TextField} from "@material-ui/core";
import {useAuthDispatch} from "../../../../contexts/Auth";
import {getEntityTitle, getFieldLabel, RenderEditableField} from "./Field";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {Autocomplete} from "@material-ui/lab";
import {graphQLApi} from "../../../../services/GraphQLApi";

export default function EntityField({
                                      field,
                                      fields,
                                      entityTypes,
                                      // ...rest
                                    }) {
  const {language} = useContext(LanguageContext);
  const dispatcher = useAuthDispatch();
  const {entity, getValuesOfField, setValuesOfField, setEditing} = useContext(EntityContext);
  let value = getValuesOfField(field.id)?.map(v => v.related_entity)?.pop() || null;

  const [entities, setEntities] = useState([]);
  // if (options?.length) {
  //   values = options.filter(o => values.map(v => v.field_option.id).includes(o.id));
  // }

  let entityTypeId = field?.type.match(/^entity_(\d)+$/);
  if (entityTypeId) entityTypeId = entityTypeId[1];
  let entityType = entityTypes.find(et => et.id === entityTypeId);
  let entityTypeFieldIds = [];
  ['number', 'title', 'third', 'fourth', 'fifth', 'sixth'].forEach(k => {
    if (entityType[k + "_field"]) entityTypeFieldIds.push(entityType[k + "_field"].id);
  });

  const [isLoading, setIsLoading] = useState(false);
  const loadEntities = () => {
    if (entities.length > 0) return;
    setIsLoading(true);
    const client = new graphQLApi(dispatcher);
    client.query('{' +
      'entities(filter:{entity_type_id:' + entityType.id + ',parent_id:null' + (entity.id ? ',id_ne:' + entity.id : '') + '}){' +
      '  data{id values(locale:"' + (language.locale) + '", field_id_in:[' + entityTypeFieldIds.join(',') + ']){id field_id language_id field_option{title} asset{file_thumb} text string integer decimal bool date}}' +
      '}}').then(r => {
      if (r?.entities.hasOwnProperty('data')) {
        setEntities(r.entities.data);
      }
      setIsLoading(false);
    })
  };

  // console.log(field, value, entityType, entityTypeFieldIds);
  return <RenderEditableField
    field={field}
    value={getEntityTitle(value, entityTypeFieldIds, fields)}
    onEditing={loadEntities}
  >{isLoading
    ? <LinearProgress variant={"indeterminate"} style={{width: "100%"}}/>
    : <Autocomplete
      size="small"
      fullWidth
      id={"field-" + field.id}
      options={entities}
      getOptionLabel={value => getEntityTitle(value, entityTypeFieldIds, fields)}
      getOptionSelected={(option, value) => {
        // console.log(field.name, option, value);
        return option.id === value.id;
      }}
      value={value}
      onChange={(_, value) => {
        console.log(field.id, value);
        setValuesOfField(field.id, [{related_entity: value, related_entity_id: value.id}]);
      }}
      renderInput={(params) => <TextField
        {...params}
        label={getFieldLabel(field, language)}
      />}
      onKeyUp={e => ['Enter', 'Escape'].includes(e.code) && setEditing(null)}
    />}
  </RenderEditableField>;
}
