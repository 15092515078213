import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// @material-ui/icons components
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';

import componentStyles from 'assets/theme/layouts/admin.js';
import {useIntl} from 'react-intl';
import {authUser} from 'contexts/Auth';
import EntityList from 'views/Entities/EntityList';
import {config} from 'config';
import EntityLayoutEditor from "../views/Entities/EntityLayoutEditor";

const useStyles = makeStyles(componentStyles);

export default function Entities({history, match}) {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const brandText = intl.formatMessage({id:"navbar.brand_text"});
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <>
      <>
        <Box position="relative" className={classes.mainContentNoSidebar}>
          <AdminNavbar history={history} match={match} logo={{
            innerLink: "/",
            imgSrc: (authUser().site && authUser().site.logo_uri)
              ? authUser().site.logo_uri
              : config.brandLogo.default,
            imgAlt: brandText,
          }}/>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
            id='main-content'
          >
            <Container classes={{ root: classes.mainRoot }}>
              <Routes>
                {/*<Route path={"entity/create"} element={<EntityLayoutEditor/>}/>*/}
                <Route path={"entity/:id"} element={<EntityLayoutEditor/>}/>
                {/*<Route path={"view/:viewID/entity/create"} element={<EntityLayoutEditor/>}/>*/}
                <Route path={"view/:viewID/entity/:id"} element={<EntityLayoutEditor/>}/>
                {/*<Route path={"view/:viewID/create"} element={<EntityEdit/>}/>*/}
                {/*<Route path={"view/:viewID/:id"} element={<EntityEdit/>}/>*/}
                <Route path={"view/:viewID"} element={<EntityList/>}/>
                {/*<Route path={":id"} element={<EntityEdit/>}/>*/}
                <Route path={""} element={<EntityList/>}/>
              </Routes>
              <AdminFooter width={"100%"} ml={"250px"}/>
            </Container>
          </Container>
        </Box>
      </>
    </>
  );
};
